#Select-Project-label {
  /* Style the label element if a sibling .MuiInputBase-root contains a .PopInput class */
  color: red !important;
  font-weight: bold;
}

.modal {
  position: fixed;
  z-index: 9999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  /* Black with opacity */
}

/* Modal Content */

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 50%; /* Adjust the maximum width as needed */
  max-height: 50%; /* Adjust the maximum height as needed */
  border-radius: 1rem;
}
.modal-content img {
  max-width:100%;
    max-height:100%;
    width:auto;
  border-radius: 1rem;
}
/* Close Button */
.close {
  color: red;
  font-size: 40px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}
