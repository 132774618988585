.logo-scroller {
  overflow: hidden;            /* Hide the overflow */
  white-space: nowrap;        /* Prevent line breaks to allow horizontal scrolling */
  position: relative;         /* Ensure proper positioning for child elements */
}

.logo-wrapper {
  display: flex;              /* Flexbox for horizontal layout */
  animation: scroll 30s ease-in-out infinite; /* Animation with easing */
  will-change: transform;     /* Hint for performance */
}

.logo-item {
  margin: 0 10px;            /* Space between items (adjust as necessary) */
}

/* Keyframes for the scroll animation */
@keyframes scroll {
  0% {
    transform: translateX(0); /* Start at original position */
  }
  50% {
    transform: translateX(-50%); /* Move all the way to the left */
  }
  100% {
    transform: translateX(0); /* Loop back to start position */
  }
}
.logo-scroller {
  overflow: hidden;            /* Hide the overflow */
  white-space: nowrap;        /* Prevent line breaks to allow horizontal scrolling */
  position: relative;         /* Ensure proper positioning for child elements */
}

.logo-wrapper {
  display: flex;              /* Flexbox for horizontal layout */
  animation: scroll 30s ease-in-out infinite; /* Animation with easing */
  will-change: transform;     /* Hint for performance */
}

.logo-item {
  margin: 0 10px;            /* Space between items (adjust as necessary) */
}

/* Keyframes for the scroll animation */
@keyframes scroll {
  0% {
    transform: translateX(0); /* Start at original position */
  }
  50% {
    transform: translateX(-50%); /* Move all the way to the left */
  }
  100% {
    transform: translateX(0); /* Loop back to start position */
  }
}
